<template>
  <CRow v-if="platformPermissionsLoaded" class="connect">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('connect.Deliver') }}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="has_tabs pt-0 pb-0">
          <CRow class="pb-0">
            <CCol cols="12" lg="12" class="p-0">
              <b-tabs class="no_borders mb-0 no_padding" type="is-boxed" :animated="false" v-model="activeTab">
                <b-tab-item v-if="checkPermission('connect.deliver.overviewcampaigns') || checkPermission('connect.deliver.overviewtemplates')">
                  <template slot="header">
                    <i class="mr-1 fas fa-at"/><span>{{ $t('connect.Mail') }}</span>
                  </template>
                  <template>
                    <Mail :platformPermissions="platformPermissions"/>
                  </template>
                </b-tab-item>
                <b-tab-item v-if="modules.push_notifications === 1">
                  <template slot="header">
                    <i class="mr-1 fas fa-bell"/><span>{{ $t('connect.Push_notification') }}</span>
                  </template>
                  <template>
                    <Push/>
                  </template>
                </b-tab-item>
              </b-tabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import Mail from '@/views/connect/deliver/mail/Mail.vue';
import Push from '@/views/connect/deliver/push/Push.vue';

export default {
  name: 'Deliver',
  components: {
    Mail,
    Push
  },
  data() {
    return {
      modules: { push_notifications: 0 },
      platformPermissions: [],
      platformPermissionsLoaded: false,
      activeTab: 0
    }
  },
  methods: {
    checkTabToActivate() {
      if(localStorage.getItem('deliverTabToActivate') !== null) {
        let tabToActivate = localStorage.getItem('deliverTabToActivate');
        // Activate correct tab
        if(tabToActivate === 'mail') {
          this.activeTab = 0;
        } else if(tabToActivate === 'push') {
          this.activeTab = 1;
        }
        // Remove deliverTabToActivate from the localStorage      
        localStorage.removeItem('deliverTabToActivate');
      } 
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){    
    this.checkModules();
    this.getPlatformPermissions();
    this.checkTabToActivate();
  }  
}
</script>