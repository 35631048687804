<template>
  <CRow class="deliver">
    <CCol cols="12" lg="12" md="12" class="pt-0" v-bind:class="{'pb-0' : templates.length <= perPage}">
      <CRow v-if="checkPermission('connect.deliver.addedittemplate')" class="m-0 actions">
        <CCol cols="12" xl="12" class="pt-0 pb-0">
          <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
            <div class="ml-2 mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="mr-0 link" color="primary" @click="showNewTemplate();">
                <i class="fas fa-plus mr-1"/>
                <span>{{ $t('common.Add_mail_template') }}</span>
              </CButton>
            </div>             
          </div>
        </CCol>             
      </CRow>
      <hr v-if="checkPermission('connect.deliver.addedittemplate')" class="m-0"/>
      <CRow>
        <CCol cols="12" lg="12" class="pb-0">
          <b-table class="data_table" ref="campaignTable" :data="templates" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
            <template slot-scope="props">
              <b-table-column field="name" :label="$t('common.Name')" :searchable="searchEnabled" width="20%">
                <span>{{props.row.name}}</span>
                <span class="mt-1 d-block" style="font-size: 0.8em;">{{props.row.created_by_name}} | {{props.row.created | moment("YYYY-MM-DD HH:mm")}}</span>
              </b-table-column>
              <b-table-column field="description" :label="$t('common.Description')" width="30%" :searchable="searchEnabled">
                <span>{{props.row.description ? props.row.description : '-'}}</span>
              </b-table-column>              
              <b-table-column field="status_name" :label="$t('common.Status')" :searchable="searchEnabled">
                <span>{{props.row.status_name}}</span>
              </b-table-column>
              <b-table-column field="updated" :label="$t('common.Last_update')" :searchable="searchEnabled">
                <span>{{props.row.updated_by_name }} @ {{props.row.updated | moment("YYYY-MM-DD HH:mm")}}</span>
              </b-table-column>              
              <b-table-column field="actions" :visible="checkPermission('connect.deliver.addedittemplate')">
                <div class="d-flex justify-content-lg-end">
                  <CButton class="m-0 d-inline-block" color="primary" @click="showTemplateDetails(props.row.deliver_email_template_id_external)">
                    <i class="fas fa-pen"/>
                  </CButton>
                </div>
              </b-table-column>  
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="null"/>
                <span v-else>{{ $t('common.No_mail_templates_found') }}</span>
              </div>                
            </template>                              
          </b-table>
        </CCol>
      </CRow>
    </CCol>          
  </CRow>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'Campaigns',
  props: ['platformPermissions'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      templates: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true
    }
  },
  methods: {
    getMailTemplates () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/templates/overview')
      .then(res => {      
        this.templates = res.data.data;
        // Check if the pagination should be activated
        (this.templates.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Update the overviewDataLoaded value
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showNewTemplate() {
      this.$router.push({path: '/connect/deliver/template/new'});
    },    
    showTemplateDetails(id) {
      this.$router.push({path: `/connect/deliver/template/${id.toString()}`});
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.getMailTemplates();
  }
}
</script>