<template>
  <CRow class="deliver">
    <CCol cols="12" lg="12" md="12" class="pt-0" v-bind:class="{'pb-0' : !isPaginated}">
      <CRow class="m-0 actions">
        <CCol cols="12" xl="12" class="pt-0 pb-0">
          <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
            <!-- <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="searchEnabled" size="is-small">{{ $t('common.activate_search') }}</b-switch> -->
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="mr-0 link" color="primary" @click="openSidebarRight('send_push_notification', {})">
                <i class="fas fa-plus mr-1"/>
                <span>{{ $t('connect.Send_push_notification') }}</span>
              </CButton>
            </div>             
          </div>
        </CCol>             
      </CRow>
      <hr class="m-0"/>
      <CRow>
        <CCol cols="12" lg="12" class="pb-0">
          <b-table class="data_table"
                   ref="campaignTable"
                   :data="notifications"
                   :striped="true"
                   :hoverable="true"
                   :mobile-cards="true"
                   :paginated="isPaginated"
                   :per-page="perPage"
                   :current-page.sync="currentPage"
                   :pagination-simple="isPaginationSimple"
                   :pagination-position="paginationPosition"
                   :total="totalItems"
                   backend-pagination
                   @page-change="onPageChange">
            
            <template slot-scope="props">
              <b-table-column field="title" :label="$t('common.Title')" :searchable="searchEnabled" width="20%">
                <span>{{props.row.title}}</span>
                <div class="item_tags d-flex flex-row">
                  <b-tag v-if="props.row.subtopic" class="mt-1 detail">{{ props.row.subtopic.label }}</b-tag>
                </div>                
              </b-table-column>
              <b-table-column field="message" :label="$t('common.Message')" :searchable="searchEnabled" width="60%">
                <span>{{props.row.message}}</span>
              </b-table-column>                      
              <b-table-column field="headcount" label="" width="5%">
                <span class="d-flex justify-content-start pointer" @click="openSidebarRight('headcount_details', { headcount_type: 'push notification', headcount_id_external: props.row.deliver_push_notification_id_external })">
                  <i class="icon top far fa-user mr-1"/>{{props.row.headcount}}
                </span>
              </b-table-column>
              <b-table-column field="sent" :label="$t('common.Sent')" :searchable="searchEnabled" width="15%">
                <span>{{props.row.sent | moment("YYYY-MM-DD HH:mm")}}</span>
              </b-table-column>             
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="null"/>
                <span v-else>{{ $t('connect.No_push_notifications_found') }}</span>
              </div>                
            </template>                              
          </b-table>
        </CCol>
      </CRow>
    </CCol>          
  </CRow>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'Push',
  components: {
    loadingSpinner
  },
  data() {
    return {
      notifications: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      totalItems: 0,
      perPage: 10,
      searchEnabled: false,
      startIndex: 0
    }
  },
  methods: {
    onPageChange(page) {
      // Set the startIndex value
      (page > 1) ? this.startIndex = (page - 1)*10 : this.startIndex = 0;
      // Get the posts for the list view   
      this.getNotifications();
    },        
    getNotifications () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/pushnotifications/' + this.startIndex)
      .then(res => {      
        // Clear the notifications array
        this.notifications = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_push_notifications;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the push_notifications to the notifications array
        this.notifications = res.data.data.push_notifications;
        // Check if the pagination should be activated
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Update the overviewDataLoaded value
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  mounted: function(){
    this.getNotifications();

    this.$bus.$on('update_push_notifications', () => {
      this.getNotifications();
    });
  },
  beforeDestroy() {
    this.$bus.$off('update_push_notifications');
  }
}
</script>