<template>
  <CRow class="deliver">
    <CCol cols="12" lg="12" md="12" class="pt-0" v-bind:class="{'pb-0' : !isPaginated}">
      <CRow v-if="checkPermission('connect.deliver.addeditcampaign')" class="m-0 actions">
        <CCol cols="12" xl="12" class="pt-0 pb-0">
          <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
            <div class="ml-2 mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="mr-0 link" color="primary" @click="showNewCampaign();">
                <i class="fas fa-plus mr-1"/>
                <span>{{ $t('connect.Add_mail_campaign') }}</span>
              </CButton>
            </div>             
          </div>
        </CCol>             
      </CRow>
      <hr v-if="checkPermission('connect.deliver.addeditcampaign')" class="m-0"/>
      <CRow>
        <CCol cols="12" lg="12" class="pb-0">
          <b-table class="data_table"
                   ref="campaignTable"
                   :data="campaigns"
                   :striped="true"
                   :hoverable="true"
                   :mobile-cards="true"
                   :paginated="isPaginated"
                   :per-page="perPage"
                   :current-page.sync="currentPage"
                   :pagination-simple="isPaginationSimple"
                   :pagination-position="paginationPosition"
                   :total="totalItems"
                   backend-pagination
                   @page-change="onPageChange">
                        
            <template slot-scope="props">
              <b-table-column field="subject" :label="$t('common.Subject')" :searchable="searchEnabled">
                <span @click="showCampaignDetails(props.row.deliver_email_id_external)">{{props.row.subject}}</span>
                <span class="mt-1 d-block" style="font-size: 0.8em;">{{props.row.created_by}} | {{props.row.updated | moment("YYYY-MM-DD HH:mm")}}</span>
                <div class="item_tags d-flex flex-row">
                  <b-tag v-if="props.row.subtopic" class="mt-1 detail">{{ props.row.subtopic.label }}</b-tag>
                </div>
              </b-table-column>
              <b-table-column field="sender_name" :label="$t('connect.Sender')" :searchable="searchEnabled">
                <span>{{props.row.sender_name}}</span>
              </b-table-column>
              <b-table-column field="status_name" :label="$t('common.Status')" :searchable="searchEnabled">
                <span>{{props.row.status_name}}</span>
              </b-table-column>
              <b-table-column field="scheduled" :label="$t('common.Scheduled')" :searchable="searchEnabled">
                <span v-if="props.row.scheduled">{{props.row.scheduled | moment("YYYY-MM-DD HH:mm")}}</span>
                <span v-else>-</span>
              </b-table-column>
              <b-table-column field="headcount" label="">
                <span class="d-flex pointer" @click="openSidebarRight('headcount_details', { headcount_type: 'campaign', headcount_id_external: props.row.deliver_email_id_external })">
                  <i class="icon top far fa-user mr-1"/>{{props.row.headcount}}
                </span>
              </b-table-column>
              <b-table-column field="actions" label="" width="15%" :visible="checkPermission('connect.deliver.addeditcampaign')">
                <div class="d-flex justify-content-lg-end">
                  <CButton v-if="props.row.provider_mail_id" class="m-0 d-inline-block" color="primary" @click="showCampaignDetails(props.row.deliver_email_id_external)">
                    <i class="fas fa-chevron-right"/>
                  </CButton>                
                  <CButton v-else class="m-0 d-inline-block" color="primary" @click="showCampaignDetails(props.row.deliver_email_id_external)">
                    <i class="fas fa-pen"/>
                  </CButton>
                </div>
              </b-table-column>  
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="null"/>
                <span v-else>{{ $t('connect.No_mail_campaigns_found') }}</span>
              </div>                
            </template>                              
          </b-table>
        </CCol>
      </CRow>
    </CCol>          
  </CRow>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'Campaigns',
  props: ['platformPermissions'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      campaigns: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      totalItems: 0,
      perPage: 10,
      searchEnabled: true,
      startIndex: 0
    }
  },
  methods: {
    onPageChange(page) {
      // Set the startIndex value
      (page > 1) ? this.startIndex = (page - 1)*10 : this.startIndex = 0;
      // Get the posts for the list view   
      this.getCampaigns();
    },    
    getCampaigns () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/emails/' + this.startIndex)
      .then(res => {
        // Clear the campaigns array
        this.campaigns = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_emails;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the emails to the campaigns array
        this.campaigns = res.data.data.emails;
        // Filter the data if necessary
        // if(!this.showDelivered) this.campaigns = this.campaigns.filter(i => !['delivered'].includes( i.status_tag ));
        // Check if the pagination should be activated
        if(this.campaigns.length > this.perPage) this.isPaginated = true; this.isPaginated = false;
        // Update the overviewDataLoaded value
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showNewCampaign() {
      this.$router.push({path: '/connect/deliver/campaign/new'});
    },    
    showCampaignDetails(id) {
      this.$router.push({path: `/connect/deliver/campaign/${id.toString()}`});
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.getCampaigns();  
  }
}
</script>