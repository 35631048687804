<template>
  <CRow>
    <CCol cols="12" lg="12" class="pt-0 pb-0">
      <b-tabs class="no_borders mb-0 no_padding" type="is-boxed" :animated="false" v-model="activeTab">
        <b-tab-item v-if="checkPermission('connect.deliver.overviewcampaigns')">
          <template slot="header">
            <i class="mr-1 fas fa-mail-bulk"/><span>{{ $t('connect.Campaigns') }}</span>
          </template>
          <template>
            <Campaigns :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
        <b-tab-item v-if="checkPermission('connect.deliver.overviewtemplates')">
          <template slot="header">
            <i class="mr-1 fas fa-clipboard"/><span>{{ $t('connect.Templates') }}</span>
          </template>
          <template>
            <Templates :platformPermissions="platformPermissions"/>
          </template>
        </b-tab-item>
      </b-tabs>
    </CCol>
  </CRow>
</template>

<script>
import Campaigns from '@/views/connect/deliver/mail/Campaigns.vue';
import Templates from '@/views/connect/deliver/mail/Templates.vue';

export default {
  name: 'Deliver',
  props: ['platformPermissions'],
  components: {
    Campaigns,
    Templates
  },
  data() {
    return {
      activeTab: 0
    }
  },
  methods: {
    checkTabToActivate() {
      if(localStorage.getItem('mailTabToActivate') !== null) {
        let tabToActivate = localStorage.getItem('mailTabToActivate');
        // Activate correct tab
        if(tabToActivate === 'campaigns') {
          this.activeTab = 0;
        } else if(tabToActivate === 'templates') {
          this.activeTab = 1;
        }
        // Remove mailTabToActivate from the localStorage      
        localStorage.removeItem('mailTabToActivate');
      } 
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.checkTabToActivate();
  }  
}
</script>